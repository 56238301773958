var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "col-md-12" }, [
      _c(
        "div",
        { staticClass: "form-section" },
        [
          _vm.show
            ? _c(
                "b-form",
                {
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return _vm.onSubmit.apply(null, arguments)
                    },
                  },
                },
                [
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        id: "input-group-title",
                        label: "Title",
                        "label-for": "input-title",
                      },
                    },
                    [
                      _c("b-form-input", {
                        attrs: {
                          id: "input-title",
                          type: "text",
                          required: "",
                          placeholder: "Enter title",
                        },
                        model: {
                          value: _vm.item.title,
                          callback: function ($$v) {
                            _vm.$set(_vm.item, "title", $$v)
                          },
                          expression: "item.title",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        id: "textarea-group-content",
                        label: "Content",
                        "label-for": "textarea-content",
                      },
                    },
                    [
                      _c("b-form-textarea", {
                        attrs: {
                          id: "'textarea-content",
                          placeholder: "Enter content",
                          rows: "3",
                          "max-rows": "8",
                        },
                        model: {
                          value: _vm.item.content,
                          callback: function ($$v) {
                            _vm.$set(_vm.item, "content", $$v)
                          },
                          expression: "item.content",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        id: "input-date",
                        label: "Date",
                        "label-for": "input-date",
                      },
                    },
                    [
                      _c("b-form-datepicker", {
                        staticClass: "mb-2",
                        attrs: { id: "input-date-input", required: "" },
                        model: {
                          value: _vm.item.date,
                          callback: function ($$v) {
                            _vm.$set(_vm.item, "date", $$v)
                          },
                          expression: "item.date",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "form-submit" },
                    [
                      _c(
                        "b-button",
                        { attrs: { type: "submit", variant: "secondary" } },
                        [
                          _vm.isLoading
                            ? _c("b-spinner", { attrs: { small: "" } })
                            : _vm._e(),
                          _vm._v("Submit "),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }