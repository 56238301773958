<template>
  <div class="row">
    <div class="col-md-12">
      <div class="form-section">
        <b-form v-on:submit.prevent="onSubmit" v-if="show">
          <b-form-group
            id="input-group-title"
            label="Title"
            label-for="input-title"
          >
            <b-form-input
              id="input-title"
              v-model="item.title"
              type="text"
              required
              placeholder="Enter title"
            ></b-form-input>
          </b-form-group>

          <b-form-group
            id="textarea-group-content"
            label="Content"
            label-for="textarea-content"
          >
            <b-form-textarea
              id="'textarea-content"
              v-model="item.content"
              placeholder="Enter content"
              rows="3"
              max-rows="8"
            ></b-form-textarea>
          </b-form-group>

          <b-form-group id="input-date" label="Date" label-for="input-date">
            <b-form-datepicker
              id="input-date-input"
              v-model="item.date"
              required
              class="mb-2"
            ></b-form-datepicker>
          </b-form-group>

          <div class="form-submit">
            <b-button type="submit" variant="secondary">
              <b-spinner small v-if="isLoading"></b-spinner>Submit
            </b-button>
          </div>
        </b-form>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.form-section {
  background-color: white;
  padding: 10px;
  margin-top: 10px;
}
.btn-info {
  background-color: #b5b5c3;
  border-color: #b5b5c3;
}
</style>

<script>
import { XmlEntities } from "html-entities";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
const entities = new XmlEntities();

export default {
  data() {
    return {
      isLoading: false,
      item: {},
      show: true,
      id: null,
      isEditMode: false
    };
  },
  methods: {
    onSubmit(evt) {
      evt.preventDefault();
      return new Promise(resolve => {
        this.isLoading = true;
        const url = this.isEditMode ? "notes?id=" + this.id : "notes";

        ApiService.post(url, this.item)
          .then(({ data }) => {
            this.isLoading = false;
            if (data && data.response && data.response.status) {
              this.$bvToast.toast("Note has been saved sucessfully!", {
                title: "Success",
                variant: "success"
              });
              const item = data.response.item;
              this.id = item.id;
              this.isEditMode = true;
              this.item = item;
            } else {
              this.$bvToast.toast("Something went wrong while saving note...", {
                title: "Error",
                variant: "danger"
              });
            }
            resolve();
          })
          .catch(() => {
            this.isLoading = false;
            this.$bvToast.toast("Something went wrong while saving note...", {
              title: "Error",
              variant: "danger"
            });
          });
      });
    },
    getItem: function() {
      this.id = this.$route.params.id || this.item.id || this.$route.query.id;
      this.isEditMode = !!this.id;

      if (this.isEditMode) {
        return new Promise(resolve => {
          ApiService.get("notes", { id: this.id })
            .then(({ data }) => {
              let item =
                data.response && data.response.items.length > 0
                  ? data.response.items[0]
                  : null;
              if (item) {
                item.content = entities.decode(item.content);
                this.item = item;
              } else {
                this.item = {};
                this.isEditMode = false;
              }
              resolve();
            })
            .catch(() => {
              this.$bvToast.toast(
                "Something went wrong while retrieving data from external server...",
                {
                  title: "Error",
                  variant: "danger"
                }
              );
            });
        });
      }
    }
  },
  mounted() {
    this.getItem();
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Notes", route: "notes" },
      { title: this.isEditMode ? "Edit Note" : "Add Note" }
    ]);
  },
  computed: {}
};
</script>
